import React, {useEffect} from 'react';

const GoogleAuthProcessing = (props) => {
    useEffect(() => {
        document.title = 'Authenticating | CC Tools';
    }, []);


    return (
        <>
            <div className="text-center">
                <h4 className="mb-0">{'Authenticating'}...</h4>
            </div>
        </>
    );
}

export default GoogleAuthProcessing;
