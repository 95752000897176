import React from 'react';

import Nav from "./Nav";
import Header from "./Header";
import Footer from "./Footer";
import Breadcrumbs from "./Breadcrumbs";

const MainLayout = (props) => {
    const onSidebarToggle = (e) => {
        e.preventDefault();

        document.querySelector("#main-wrapper").classList.toggle("show-sidebar");
        document.querySelector(".sidebartoggler i").classList.toggle("text-primary");

        if (document.querySelector('#main-wrapper').classList.contains('mini-sidebar')) {
            document.querySelector('#main-wrapper').classList.remove('mini-sidebar');

            document.querySelector(".sidebartoggler").checked = !1;
            document.querySelector("#main-wrapper").setAttribute("data-sidebartype", "full");
        } else {
            document.querySelector('#main-wrapper').classList.add('mini-sidebar');

            document.querySelector(".sidebartoggler").checked = !0;
            document.querySelector("#main-wrapper").setAttribute("data-sidebartype", "mini-sidebar");
        }
    }


    return (
        <>
            <Nav
                onSidebarToggle={onSidebarToggle}
            />

            <div className="body-wrapper">
                <Header
                    onSidebarToggle={onSidebarToggle}
                />
                <div className={'container-fluid mw-100'}>
                    <Breadcrumbs />

                    {props.children}

                    <Footer/>
                </div>
            </div>

            <div className="dark-transparent sidebartoggler" onClick={onSidebarToggle}></div>
        </>
    );
}

export default MainLayout;
