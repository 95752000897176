import React, {useEffect} from 'react';

import SimpleCardContainer from "../ui/SimpleCardContainer";
import {useApi} from "../../providers/ApiProvider";
import UsersModel from "../../models/UsersModel";
import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";
import {useAuth} from "../../providers/AuthProvider";
import {useParams} from "react-router-dom";

function UserEdit(props) {
    const params = useParams();
    const authContext = useAuth();
    const userData = authContext.user;
    const breadcrumbs = useBreadcrumbs();
    const apiContext = useApi();
    const usersModel = new UsersModel(apiContext.api);

    const [billingPlan, setBillingPlan] = React.useState('free');
    const [name, setName] = React.useState('');
    const [types, setTypes] = React.useState({});


    useEffect(() => {
        breadcrumbs.update({
            title: 'Edit user information',
            breadcrumbs: [
                {label: 'Users', url: '/users'},
                {label: 'Edit user information'}
            ]
        });

        document.title = 'Edit user information | CC Tools';

        usersModel.view(params.userId).then((response) => {
            if (response !== false) {
                setName(response.name);
                setBillingPlan(response.type);
            }
        });
    }, []);


    const onSubmit = (e) => {
        e.preventDefault();

        usersModel.update(params.userId, {
            'type': billingPlan,
            'name': name,
        }).then((response) => {
            if (response !== false) {
                window.location.href = '/users';
            }
        });
    }


    const onImpersonate = (e) => {
        e.preventDefault();

        usersModel.impersonate(params.userId).then((response) => {
            if (response !== false) {
                const formattedResponse = {
                    access_token: response
                }
                let user = usersModel.processAuthResponse(formattedResponse);
                if (user) {
                    authContext.logout();
                    authContext.login(user);
                    window.location.href = '/';
                }
            }
        });
    }


    return (
        <SimpleCardContainer title={'Edit user information'}>
            <form className={'py-2'}>
                <div className={'row'}>
                    <div className={'col-md-6'}>
                        <div className={'form-group'}>
                            <label className="form-label fw-semibold mb-2">{'Full name'}</label>

                            <input id={'cd-name'} className={'form-control'} value={name} onChange={(e) => {
                                setName(e.target.value)
                            }}/>
                        </div>

                        <div className={'form-group mt-3'}>
                            <label className="form-label fw-semibold mb-2">{'Current billing plan'}</label>

                            <select id={'cd-button'} className={'form-control form-select'} value={billingPlan} onChange={(e) => {
                                setBillingPlan(e.target.value)
                            }}>
                                {Object.keys(types).map((key) => {
                                    return <option value={key} key={key}>{types[key]}</option>
                                })}
                            </select>

                            <button className="btn btn-primary mt-3" onClick={onSubmit}>{'Save changes'}</button>
                        </div>
                    </div>
                    <div className={'col-md-6'}>
                        <div className="bordered-tip pb-4 pt-4">
                            <div className="alert alert-warning fs-2">
                                <strong>{'Warning!'}</strong><br/>
                                {'You can operate on user\'s behalf now.'}

                                <button className={'btn btn-danger d-flex align-items-center mt-3'} onClick={onImpersonate}>
                                    <i className={'ti ti-status-change fs-4 me-2'}></i>
                                    {'Impersonate user'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </SimpleCardContainer>
    );
}

export default UserEdit;
