import jwtDecode from "jwt-decode";
import {userStructure} from "../providers/AuthProvider";

/**
 * Users model
 *
 * @param {ApiHelper} api
 */
class UsersModel {
    constructor(api) {
        this.api = api;
    }


    getLabels(fields) {
        const labels = {
            name: 'Name',
            email: 'Email',
            type: 'Type',
            businesses_count: 'Businesses'
        };

        let results = [];
        for (let i = 0; i < fields.length; i++) {
            results.push(labels[fields[i]]);
        }

        return results;
    }


    async list(fields, sorting =  {}) {
        let url = '/users';
        let query = {};
        if (sorting.search) {
            query.filter = sorting.search;
        }
        if (sorting.sort && sorting.direction) {
            query.sort = sorting.sort;
            query.direction = sorting.direction;
        }
        if (sorting.type) {
            query.type = sorting.type;
        }
        if (sorting.page) {
            query.page = sorting.page;
        }
        if (Object.keys(query).length) {
            url += '?' + new URLSearchParams(query).toString();
        }

        const response = await this.api.getRequest(url);

        let results = [];
        if (response.success === true && response.data) {
            results = response.data;
        }

        return results;
    }


    async view(pk) {
        const url = '/users/' + pk;

        const response = await this.api.getRequest(url);
        return (response.success === true)?(response.data):false;
    }


    async create(data) {
        const url = '/users/create';

        const response = await this.api.postRequest(url, data);
        return (response.success === true)?(response.data):false;
    }


    async update(pk, data) {
        const url = '/users/' + pk;

        const response = await this.api.postRequest(url, data);
        return (response.success === true)?(response.data):false;
    }


    async delete(pk) {
        const url = '/users/' + pk + '/delete';

        const response = await this.api.deleteRequest(url);
        return (response !== false);
    }


    async impersonate(pk) {
        const url = '/users/' + pk + '/impersonate';

        const response = await this.api.postRequest(url);
        return (response.success === true)?(response.data):false;
    }


    processAuthResponse(response) {
        if (!response.access_token) {return false;}

        const decodedAccessToken = jwtDecode(response.access_token);
        let decodedRefreshToken = null;
        if (response.refresh_token) {
            decodedRefreshToken = jwtDecode(response.refresh_token);
        }

        let user = JSON.parse(JSON.stringify(userStructure));
        user.id = decodedAccessToken.user_id;
        user.access_token = response.access_token;
        user.access_token_expires_at = decodedAccessToken.exp;
        user.refresh_token = response.refresh_token;
        user.refresh_token_expires_at = (decodedRefreshToken)?(decodedRefreshToken.exp):null;

        Object.keys(decodedAccessToken).forEach((key) => {
            if (['user_id', 'token'].indexOf(key) === -1) {
                user[key] = decodedAccessToken[key];
            }
        });

        return user;
    }
}

export default UsersModel;