import React from 'react';

export default function AttributeLabels({}) {


    return (
        <>
            <h1>Attribute Labels</h1>
        </>
    );
}
