import {createBrowserRouter} from "react-router-dom";
import App from "./App";
import {RequireAuth} from "./providers/AuthProvider";
import Home from "./components/common/Home";
import Auth from "./components/auth/Auth";
import Login from "./components/auth/Login";
import React from "react";
import Users from "./components/users/Users";
import ForgotPassword from "./components/auth/ForgotPassword";
import Error404 from "./components/errors/Error404";
import Logout from "./components/auth/Logout";
import MainLayout from "./components/layout/MainLayout";
import ForgotPasswordConfirm from "./components/auth/ForgotPasswordConfirm";
import Register from "./components/auth/Register";
import UsersList from "./components/users/UsersList";
import UserEdit from "./components/users/UserEdit";
import Simple from "./components/common/Simple";
import AttributeValues from "./components/import/AttributeValues";
import AttributeLabels from "./components/import/AttributeLabels";
import Products from "./components/import/Products";
import ExportAttributeValues from "./components/export/AttributeValues";
import ExportProducts from "./components/export/Products";
import ExportCategories from "./components/export/Categories";

export const router = createBrowserRouter([
    {
        path: "/",
        Component: App,
        children: [
            {
                index: true,
                //loader: homeLoader,
                element: (<RequireAuth><MainLayout><Home/></MainLayout></RequireAuth>),
            },
            {
                path: "auth",
                Component: Auth,
                children: [
                    {
                        path: "login",
                        Component: Login,
                    },
                    {
                        path: "register",
                        Component: Register,
                    },
                    {
                        path: "forgot-password",
                        children: [
                            {
                                path: "confirm",
                                Component: ForgotPasswordConfirm,
                            },
                            {
                                index: true,
                                Component: ForgotPassword,
                            }
                        ],
                    },
                    {
                        path: "logout",
                        Component: Logout,
                    },
                ],
            },
            {
                path: "import",
                element: (<RequireAuth><MainLayout><Simple/></MainLayout></RequireAuth>),
                children: [
                    {
                        path: "attribute-values",
                        Component: AttributeValues,
                    },
                    {
                        path: "attribute-labels",
                        Component: AttributeLabels,
                    },
                    {
                        path: "products",
                        Component: Products,
                    },
                ],
            },
            {
                path: "export",
                element: (<RequireAuth><MainLayout><Simple/></MainLayout></RequireAuth>),
                children: [
                    {
                        path: "attribute-values",
                        Component: ExportAttributeValues,
                    },
                    {
                        path: "products",
                        Component: ExportProducts,
                    },
                    {
                        path: "categories",
                        Component: ExportCategories,
                    },
                ],
            },
            {
                path: "users",
                element: (<RequireAuth><Users /></RequireAuth>),
                children: [
                    {
                        path: ":userId/edit",
                        Component: UserEdit,
                    },
                    {
                        index: true,
                        Component: UsersList,
                    },
                ],
            },
        ],
    },
    {
        path: "*",
        Component: Error404,
    }
]);
