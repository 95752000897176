import React, {useState, useCallback, useEffect} from "react";

import SimpleBar from "simplebar-react";
import {useAuth} from "../../../providers/AuthProvider";

import imgProfileTmp from 'modernize-essentials/dist/images/profile/user-1.jpg';
import imgIconAccount from 'modernize-essentials/dist/images/svgs/icon-account.svg';
import {Link} from "react-router-dom";
import {useApi} from "../../../providers/ApiProvider";


function HeaderProfile(props) {
    const authContext = useAuth();
    const userData = authContext.user;
    const apiContext = useApi();
    const [types, setTypes] = React.useState({});

    let imgProfile = imgProfileTmp;
    if (userData.avatar) {
        imgProfile = userData.avatar;
    }


    useEffect(() => {
    }, []);

    return <>
        <a className="nav-link pe-0" href="#" data-bs-toggle="dropdown" aria-expanded="false">
            <div className="d-flex align-items-center">
                <div className="user-profile-img d-flex">
                    <img src={imgProfile} className="rounded-circle" width="35" height="35" alt=""/>
                </div>
            </div>
        </a>
        <div className="dropdown-menu content-dd dropdown-menu-end dropdown-menu-animate-up">
            <SimpleBar autoHide={true} className={'profile-dropdown position-relative'}>
                <div className="py-3 px-7 pb-0">
                    <h5 className="mb-0 fs-5 fw-semibold">{'User Profile'}</h5>
                </div>
                <div className="d-flex align-items-center py-9 mx-7 border-bottom">
                    <img src={imgProfile} className="rounded-circle" width="80" height="80" alt=""/>
                    <div className="ms-3">
                        <h5 className="mb-1 fs-3">{userData.name}</h5>
                        <span className="mb-1 d-block text-dark">{types[userData.type]}</span>
                        <p className="mb-0 d-flex text-dark align-items-center gap-2">
                            <i className="ti ti-mail fs-4"></i>
                            {userData.email}
                        </p>
                    </div>
                </div>
                <div className="message-body">
                    <Link to={'/settings/connected-accounts'} reloadDocument className="py-8 px-7 mt-8 d-flex align-items-center">
                        <span className="d-flex align-items-center justify-content-center bg-light rounded-1 p-6">
                            <img src={imgIconAccount} alt="" width="24" height="24" />
                        </span>
                        <div className="w-75 d-inline-block v-middle ps-3">
                            <h6 className="mb-1 bg-hover-primary fw-semibold">{'My Accounts'}</h6>
                            <span className="d-block text-dark">{'Connected Google accounts'}</span>
                        </div>
                    </Link>
                </div>
                <div className="d-grid py-4 px-7 pt-8">
                    <a href="/auth/logout" className="btn btn-outline-primary">{'Log Out'}</a>
                </div>
            </SimpleBar>
        </div>
    </>
}

export default HeaderProfile;
