import React from "react";

function TableEditableRow(props) {


    const onEdit = (e) => {
        e.preventDefault();

        props.onEdit(props.data.pk);
    }


    const onDelete = (e) => {
        e.preventDefault();

        if (window.confirm('Are you sure you want to delete this item?')) {
            props.onDelete(props.data.pk);
        }
    }


    if (props.loading === true) {
        return (
            <tr>
                {
                    [...Array(props.columns+1)].map((item, index) => {
                        return (
                            <td className="align-middle text-nowrap" key={index}>
                                <div className="progress mt-4">
                                    <div className="progress-bar progress-bar-striped bg-dark progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{width: '100%'}}></div>
                                </div>
                            </td>
                        );
                    })
                }
            </tr>
        );
    } else if (props.loading === undefined && props.data.data) {
        return (
            <tr>
                {props.data.data.map((item, index) => {
                    return (
                        <td key={index}>{item}</td>
                    )
                })}
                <td style={{width: '180px'}}>
                    <div className="d-flex align-items-center justify-content-end gap-3">
                        <a href="#" className={'btn btn-light-primary btn-circle d-inline-flex align-items-center justify-content-center'} onClick={onEdit}>
                            <i className="fs-5 ti ti-edit text-primary"></i>
                        </a>
                        <button type="button" className={'btn btn-light-danger btn-circle d-inline-flex align-items-center justify-content-center'} onClick={onDelete}>
                            <i className="fs-5 ti ti-trash text-danger"></i>
                        </button>
                    </div>
                </td>
            </tr>
        );
    }

    return (<></>);
}

export default TableEditableRow;
