import React from 'react';

import imgLogo from '../../assets/img/logo.jpg';

import imgProfileTmp from 'modernize-essentials/dist/images/profile/user-1.jpg';

import SimpleBar from "simplebar-react";
import {useAuth} from "../../providers/AuthProvider";



const Nav = (props) => {
    const authContext = useAuth();
    const userData = authContext.user;

    let imgProfile = imgProfileTmp;
    if (userData.avatar) {
        imgProfile = userData.avatar;
    }


    const onLogoutClick = (e) => {
        e.preventDefault();

        window.location.href = '/auth/logout';
    }


    let menuDefault = {
        'import': {
            'label': 'Import from WC',
            'items': {
                'import-attr-values': {
                    'icon': 'table-down',
                    'title': 'Import Attribute Values',
                    'url': '/import/attribute-values'
                },
                'import-attr-labels': {
                    'icon': 'tags',
                    'title': 'Import Attribute Labels',
                    'url': '/import/attribute-labels'
                },
                'import-attr-products': {
                    'icon': 'box',
                    'title': 'Import Products',
                    'url': '/import/products'
                },
            }
        },
        'export': {
            'label': 'Export to M2',
            'items': {
                'export-attribute-values': {
                    'icon': 'table-export',
                    'title': 'Export Attribute Values',
                    'url': '/export/attribute-values'
                },
                'export-products': {
                    'icon': 'box',
                    'title': 'Export Products',
                    'url': '/export/products'
                },
                'export-categories': {
                    'icon': 'subtask',
                    'title': 'Export Categories',
                    'url': '/export/categories'
                },
            }
        },
    }
    const [menu, setMenu] = React.useState(menuDefault);
    const isElementActive = (key, section) => {
        const simpleKeys = {
            'import': ['import-attr-values', 'import-attr-labels', 'import-attr-products'],
            'export': ['export-attribute-values', 'export-products', 'export-categories'],
        };

        if (simpleKeys[section].indexOf(key) !== -1) {
            if (window.location.pathname.indexOf(menu[section]['items'][key].url) !== -1) {
                return true;
            }
        }

        return false;
    }


    return (
        <aside className="left-sidebar">
            <div>
                <div className="brand-logo d-flex align-items-center justify-content-between">
                    <a href="/" className="text-nowrap logo-img">
                        <img src={imgLogo} className="dark-logo" width="180" alt=""/>
                    </a>
                    <div className="close-btn d-lg-none d-block sidebartoggler cursor-pointer" id="sidebarCollapse" onClick={props.onSidebarToggle}>
                        <i className="ti ti-x fs-8 text-muted"></i>
                    </div>
                </div>
                <SimpleBar autoHide={true} className={'sidebar-nav scroll-sidebar'}>
                    <ul id="sidebarnav">
                        {Object.keys(menu).map((section, sIndex) => {
                            return (
                                <React.Fragment key={sIndex}>
                                    <li className="nav-small-cap">
                                        <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                                        <span className="hide-menu">{menu[section]['label']}</span>
                                    </li>
                                    {Object.keys(menu[section]['items']).map((key, index) => {
                                        const element = menu[section]['items'][key];
                                        return (
                                            <li className={`sidebar-item ${isElementActive(key, section) ? 'selected' : ''}`} key={sIndex+'-'+index}>
                                                <a className={`sidebar-link ${isElementActive(key, section) ? 'active' : ''}`} href={element.url} aria-expanded="false">
                                                    <span>
                                                        <i className={`ti ti-${element.icon}`}></i>
                                                    </span>
                                                    <span className="hide-menu">{element.title}</span>
                                                    {/*typeof element.count === 'number'*/(element.count !== undefined) && (
                                                        <span className="hide-menu" style={{marginLeft: 'auto'}}><span className="align-items-center badge bg-primary d-flex fs-2 justify-content-center p-0 px-1 py-1">{element.count}</span></span>
                                                    )}
                                                </a>
                                            </li>
                                        );
                                    })}
                                </React.Fragment>
                            );
                        })}
                    </ul>
                </SimpleBar>
                <div className="fixed-profile p-3 bg-light-secondary rounded sidebar-ad mt-3">
                    <div className="hstack gap-3">
                        <div className="john-img">
                            <img src={imgProfile} className="rounded-circle" width="40" height="40" alt=""/>
                        </div>
                        <div className="john-title">
                            <h6 className="mb-0 fs-4 fw-semibold">{userData.name}</h6>
                            <span className="fs-2 text-dark">{userData.type}</span>
                        </div>
                        <button className="border-0 bg-transparent text-primary ms-auto" tabIndex="0" onClick={onLogoutClick}>
                            <i className="ti ti-power fs-6"></i>
                        </button>
                    </div>
                </div>
            </div>
        </aside>
    );
}

export default Nav;
