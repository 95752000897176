import React, {useEffect} from 'react';
import ButtonWithLoading from "../layout/ui/ButtonWithLoading";
import {useApi} from "../../providers/ApiProvider";
import ImportModel from "../../models/ImportModel";
import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";
import {useNotifications} from "../../providers/NotificationsProvider";

export default function AttributeValues({}) {
    const breadcrumbs = useBreadcrumbs();
    const notifications = useNotifications();
    const apiContext = useApi();
    const importModel = new ImportModel(apiContext.api);

    const [loading, setLoading] = React.useState(false);
    const [stats, setStats] = React.useState({});


    useEffect(() => {
        breadcrumbs.update({
            title: 'Import Attribute Values',
            breadcrumbs: []
        });

        document.title = 'Import Attribute Values | CC Tools';
    }, []);


    const onError = (error) => {
        notifications.notify('Error', 'error');
        console.log(error);
    }


    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        importModel.attributeValues(onError).then((response) => {
            notifications.notify('Attribute Values Imported', 'success');

            if (response && response.stats) {
                setStats(response.stats);
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    return (
        <>
            <h1>Import Attribute Values</h1>
            <p>Fetch actual attribute values from WooCommerce and store locally.</p>

            <ButtonWithLoading
                className="btn-primary"
                label="Start Import"
                loadingLabel="Fetching Attribute Values"
                loading={loading}
                noOriginalIconClassName={true}
                iconClassName="ti ti-refresh fs-4 me-2"
                onClick={onSubmit}
            />

            {stats && (
                <div className="mt-5">
                    <h2>Import Stats</h2>
                    {Object.keys(stats).map((key) => (
                        <div key={key} className="mb-2">
                            <strong>{key}</strong>: {stats[key]}
                        </div>
                    ))}
                </div>
            )}
        </>
    );
}
