import React from 'react';

export default function Categories({}) {


    return (
        <>
            <h1>Categories</h1>
        </>
    );
}
