import React from 'react';

export default function AttributeValues({}) {


    return (
        <>
            <h1>Export Attribute Values</h1>
        </>
    );
}
