import React, {useEffect} from 'react';

import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";
import {Link} from "react-router-dom";

import {useApi} from "../../providers/ApiProvider";

function Home(props) {
    const breadcrumbs = useBreadcrumbs();
    const apiContext = useApi();
    const [dataItems, setDataItems] = React.useState({});


    useEffect(() => {
        breadcrumbs.update({
            title: 'Welcome to CC Tools',
            breadcrumbs: []
        });

        document.title = 'Welcome | CC Tools';
    }, []);


    return (
        <>
        </>
);
}

export default Home;
