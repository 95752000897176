import React from 'react';

export default function Products({}) {


    return (
        <>
            <h1>Import Products</h1>
        </>
    );
}
