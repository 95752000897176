import React from 'react';

import imgLogo from '../../assets/img/logo.jpg';
import HeaderProfile from "./ui/HeaderProfile";

class Header extends React.Component {
    constructor(props) {
        super(props);
    }


    componentDidMount() {
        window.addEventListener('scroll', function () {
            if (document.querySelector('.app-header')) {
                if (window.scrollY > 60) {
                    document.querySelector('.app-header').classList.add('fixed-header');
                } else {
                    document.querySelector('.app-header').classList.remove('fixed-header');
                }
            }
        });

        window.addEventListener('resize', this.onWindowResize);
        this.onWindowResize();
    }


    onWindowResize() {
        if (document.querySelector('#main-wrapper')) {
            if (window.innerWidth < 1300) {
                document.querySelector('#main-wrapper').setAttribute('data-sidebartype', 'mini-sidebar');
                document.querySelector('#main-wrapper').classList.add('mini-sidebar');
            } else {
                document.querySelector('#main-wrapper').setAttribute('data-sidebartype', 'full');
                document.querySelector('#main-wrapper').classList.remove('mini-sidebar');
            }
        }
    }


    render() {
        return (<>
            <header className="app-header">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link sidebartoggler nav-icon-hover ms-n3" id="headerCollapse" href="#" onClick={this.props.onSidebarToggle}>
                                <i className="ti ti-menu-2"></i>
                            </a>
                        </li>
                        <li className="nav-item d-none">
                            <a className="nav-link nav-icon-hover" href="#" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="ti ti-search"></i>
                            </a>
                        </li>
                    </ul>
                    <div className="d-block d-lg-none">
                        <img src={imgLogo} width="180" alt=""/>
                    </div>
                    <button className="navbar-toggler p-0 border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="p-2">
                            <i className="ti ti-dots fs-7"></i>
                        </span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                        <div className="d-flex align-items-center justify-content-between">
                            <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-center">
                                <li className="nav-item dropdown">
                                    <HeaderProfile/>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </>);
    }
}

export default Header;
